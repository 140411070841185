// // Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		grecaptcha.execute('6LdsacQZAAAAAEcz4Oj94POvOEMNsZPiS-qBIjXp', { action: 'homepage' }).then(function (token) {
			console.log('token: ' + token);
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$(".section-cookies").slideToggle();
});

// Ajax referral form processing
$('#mediaCentre form').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/media-centre/',
		data: $(this).serialize()
	}).done(function (response) {
		// If no errors
		if (response == 'success') {

			$.ajax({
				method: "POST",
				url: "api/media-cookies/",
			}).done(function (response) { });

			$('.loading_overlay').fadeTo(300, 0.5);

			// Show thank you message
			$('#mediaCentre .sub-thanks').fadeIn();

			// Hide Form and reset
			$('#mediaCentre form').hide();

			// Remove any errors that may still be there
			$('#mediaCentre form .sub-errors').html('');

			$('html, body').animate({ scrollTop: 0 }, 700, 'swing');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				location.reload();
			}, 8000);
		}
		else {
			$('#mediaCentre form .sub-errors').fadeIn().html(response);
		}
	});
});

if ($('#mediaCentre').length) {
}

// Bold second word of generic page and product main titles
if ($('.generic-page, #mediaCentre, .products:not(.sub-product)').length) {
	const title = $('.section-hero h1'),
		titleSplit = title.text().split(' ');

	// If a 2nd word doesn't exist in the title
	if (titleSplit[1] != undefined) {
		titleSplit[1] = '<span class="mod-bold">' + titleSplit[1] + '</span>';
	} else {
		// If title is a single word, bold it
		titleSplit[0] = '<span class="mod-bold">' + titleSplit[0] + '</span>';
	}

	// Join two seperate words together
	titleSplit.join(' ');

	// Insert new html into hero
	title.html(titleSplit);
}


// Mobile Nav
$('.sub-mobile-button').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('.header-main .sub-header-wrapper nav').fadeToggle();
});

// Mobile Nav Sub Menus
$('.mod-dropdown').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$(this).next('.list-sub-nav').toggleClass('mod-active');
	$(this).find('.mobile-menu-arrow-down').fadeToggle();
});

// Filtering section for media centre
if ($('#mediaCentre').length) {
	// If categories checkbox array changes
	$('.download-type').change(function () {

		// Loop through each option
		$('.download-type option:selected').each(function () {
			$('.list-downloads li').hide();
			$('.list-downloads li[data-cat="' + $(this).val() + '"]').show();

			if ($(this).val() === 'Select all downloads') {
				$('.list-downloads li').show();
			}
		});

	});

	// Change style of agreement checkbox on router order form
	$('input[type="checkbox"]').on('change', function () {

		$('.sub-checkbox-wrap').removeClass('mod-checked');

		if ($(this).is(":checked")) {
			$(this).parents('label').addClass('mod-checked');
			$(this).parents('.sub-checkbox-wrap').addClass('mod-checked');
		}
	});

	// Show fields based on radio input
	$('.show-next-element').click(function () {
		$(this).parents('.form-element').next('.mod-hidden').addClass('mod-show');
	});

	// Hide fields based on radio input
	$('.hide-next-element').click(function () {
		$(this).parents('.form-element').next('.mod-hidden').removeClass('mod-show');
	});
}

// Identify the device as touch device
function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Google Maps
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['Respair', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#e9e9e9" }, { "lightness": 17 }] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 20 }] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }, { "lightness": 17 }] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [{ "color": "#ffffff" }, { "lightness": 29 }, { "weight": 0.2 }] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 18 }] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [{ "color": "#ffffff" }, { "lightness": 16 }] }, { "featureType": "poi", "elementType": "geometry", "stylers": [{ "color": "#f5f5f5" }, { "lightness": 21 }] }, { "featureType": "poi.park", "elementType": "geometry", "stylers": [{ "color": "#dedede" }, { "lightness": 21 }] }, { "elementType": "labels.text.stroke", "stylers": [{ "visibility": "on" }, { "color": "#ffffff" }, { "lightness": 16 }] }, { "elementType": "labels.text.fill", "stylers": [{ "saturation": 36 }, { "color": "#333333" }, { "lightness": 40 }] }, { "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "geometry", "stylers": [{ "color": "#f2f2f2" }, { "lightness": 19 }] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [{ "color": "#fefefe" }, { "lightness": 20 }] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [{ "color": "#fefefe" }, { "lightness": 17 }, { "weight": 1.2 }] }],
			{ name: 'Styled Map' }
		);
	}

	if ($('#contact').length) {
		createMap('map', 51.752970, -1.594238, 12, true);
	}
}

// Function for popout window
function windowPopup(url, width, height) {
	// Calculate the position for the popup so it’s centered on the screen.
	const left = (screen.width / 2) - (width / 2);
	const top = (screen.height / 2) - (height / 2);

	// opens a window with the given url & set position from above
	window.open(url, "", "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,width=" + width + ",height=" + height + ",top=" + top + ",left=" + left);
}

// Ensure the function exists on the page before we call it.
if (!!window['gdprSubscribeModal']) {
	// The form of which to attach the modal to.
	const formSelector = '#contact-form';
	// The url to link to for the privacy policy.
	const privacyPolicyURL = 'privacy-policy.html';
	// Color of the links in the modal
	const linkColour = '#000';
	// Color of the buttons in the modal.
	const buttonTextColour = '#000';

	// Call this per form.
	gdprSubscribeModal(formSelector, privacyPolicyURL, linkColour, buttonTextColour);
}

// This automatically adds 'data-sitekey' to the buttons.
// and programmically binds a callback for captcha completion.
// add '.captcha-ignore' as a class on the button to ignore
// the button and disable captcha on that form.
var CaptchaCallback = function () {
	var SITE_KEY = ''; // TODO: add your site key from recaptcha

	// Don't execute if no site key present.
	if (SITE_KEY === '') {
		return;
	}

	// Get all form elements on page.
	var pageForms = $('form');

	// Ensure the page has a form on it, at least.
	if (pageForms.length <= 0) {
		return;
	}


	pageForms.each(function (formIncId, form) {
		// Grab all of the 'submit' buttons which are attached to the form.
		var submissionButtons = $(form).find('input[type="submit"]:not(.captcha-ignore)');

		if (submissionButtons.length <= 0) {
			return; // Return here is similar to 'continue' within a loop.
		}

		submissionButtons.each(function (btnIncId, button) {
			$(button).attr('data-sitekey', SITE_KEY);

			grecaptcha.render(button, {
				sitekey: SITE_KEY,
				callback: function () {
					$(button).closest('form').submit();
				}
			});
		});
	});
};